<template>
  <div id="header-page">
      <h2 class="white--text font-weight-medium">{{title}}</h2>
      <v-divider class="mt-4 mb-7" color="#fff"></v-divider>
  </div>
</template>

<script>
export default {
    name: 'HeaderPage',
    props: ['title']
}
</script>

<style>

</style>