<template>
  <footer class="footer">
      <p>Copyright©2021 M&M Toy Shop</p>
  </footer>
</template>

<script>
export default {

}
</script>

<style scoped>
.footer {
    width: 100%;
    height: 35px;
    background: #2C3036;
    line-height: 35px;
    text-align: center;
    position: fixed;
    bottom: 0;
}
.footer p {
    color: #fff;
    font-size: 13px;
}
</style>