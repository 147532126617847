<template>
  <div class="navigation">
    <v-app-bar color="white" height="80" class="app-bar" app>
      <v-app-bar-nav-icon
        class="hidden-md-and-up ml-2"
        @click.stop="drawer = !drawer"
        color="#fff"
      ></v-app-bar-nav-icon>

      <div class="d-flex align-center ml-5 white--text">
        <v-img
          src="@/assets/logo.png"
          width="50px"
          class="mr-4"
          @click.prevent="$router.push('/classroom')"
        >
        </v-img>
        <h1 class="hidden-sm-and-down mmtoyshop-name">
          M&M Toy Shop Management
        </h1>
      </div>
      <v-spacer></v-spacer>

      <div class="mr-4 white--text">Admin : {{ name }}</div>
      <v-btn icon class="mr-1" @click.prevent="logOut">
        <v-icon color="white">mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      fixed
      left
      width="300px"
      color="#2C3036"
      :permanent="$vuetify.breakpoint.mdAndUp"
      app
    >
      <v-list nav class="pa-0">
        <v-list class="py-4" style="background: #383e45">
          <v-list-item> </v-list-item>
        </v-list>

        <v-list-item-group v-model="group" class="px-4 py-5">
          <v-list-item
            color="#fff"
            dark
            v-for="(item, index) in itemsMenu"
            :key="index"
            link
            :to="item.to"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import Auth from '@/services/Auth.service';
export default {
  name: 'Navigation',
  data() {
    return {
      itemsMenu: [],
      name: '',
      drawer: true,
      group: null,
    };
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  async created() {
    await this.checkAuthenticate();
    let accountLocalStorage = JSON.parse(
      localStorage.getItem('admin_mmtoyshop')
    );
    this.name = accountLocalStorage.name;
    if (accountLocalStorage.role === 3) {
      this.itemsMenu = [
        {
          title: 'เพิ่ม Tracking Number',
          to: '/management/add-tracking',
        },
        { title: 'รายการ Tracking Number', to: '/management/tracking-list' },
        {
          title: 'สินค้าทั้งหมด',
          to: '/management/products',
        },
        { title: 'บัญชีแอดมิน', to: '/management/accounts' },
        { title: 'ข้อมูลลูกค้า', to: '/management/customer' },
        { title: 'เพิ่มออเดอร์', to: '/management/add-order' },
        { title: 'สรุปออเดอร์', to: '/management/orders' },
        { title: 'ลูกค้าสัมพันธ์', to: '/management/customer-orders' },
      ];
    }
    if (accountLocalStorage.role === 2) {
      this.itemsMenu = [
        {
          title: 'เพิ่ม Tracking Number',
          to: '/management/add-tracking',
        },
        { title: 'รายการ Tracking Number', to: '/management/tracking-list' },
        {
          title: 'สินค้าทั้งหมด',
          to: '/management/products',
        },
        { title: 'สรุปออเดอร์', to: '/management/orders' },
      ];
    }
    if (accountLocalStorage.role === 1) {
      this.itemsMenu = [
        { title: 'เพิ่มออเดอร์', to: '/management/add-order' },
        { title: 'สรุปออเดอร์', to: '/management/orders' },
        { title: 'ลูกค้าสัมพันธ์', to: '/management/customer-orders' },
      ];
    }
  },
  methods: {
    async checkAuthenticate() {
      try {
        const checkAuth = await Auth.checkAuthenticateToken();
        return checkAuth.msg;
      } catch (error) {
        localStorage.removeItem('admin_mmtoyshop');
        this.$router.push('/management/login');
        return;
      }
    },
    logOut() {
      Swal.fire({
        icon: 'question',
        title: 'ออกจากระบบ?',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        confirmButtonColor: '#2196F3',
        cancelButtonColor: '#90A4AE',
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          localStorage.removeItem('admin_mmtoyshop');
          this.$router.push('/management/login');
        }
      });
    },
  },
};
</script>

<style scoped>
.app-bar {
  background: #2c3036 !important;
}
.mmtoyshop-name {
  font-size: 18px;
  font-weight: 500;
}
</style>
